export const content = {
  seo: {
    title: 'Zgoda RODO',
    desc: 'Zgoda RODO',
    url: 'zgoda-rodo.js'
  },
  title: {
    pl: 'Zgoda RODO',
    en: 'Rodo consent',
  },
  desc: {
    pl: 'Wypełnij formularz, aby wyrazić zgodę na przetwarzanie danych osobowych przez spółki Grupy OMIDA w celach kontaktowych i ofertowych, zgodnie z obowiązującymi przepisami RODO.',
    en: 'Rodo consent',
  },
  fields: {
    firstName: {
      label: { pl: 'Imię', en: 'Firstname' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: ['CONSENT'],
    },
    lastName: {
      label: { pl: 'Nazwisko', en: 'Lastname' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: ['CONSENT'],
    },
    email: {
      label: { pl: 'Email', en: 'Email' },
      placeholder: { pl: '', en: '' },
      type: 'email',
      isRequired: ['CONSENT'],
    },
    phone: {
      label: { pl: 'Telefon', en: 'Number' },
      placeholder: { pl: '', en: '' },
      type: 'tel',
      isRequired: ['CONSENT'],
    },
    company: {
      label: { pl: 'Firma', en: 'Company' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: ['CONSENT'],
    },
    nip: {
      label: { pl: 'NIP', en: 'TAX ID' },
      placeholder: { pl: '', en: '' },
      type: 'string',
      isRequired: ['CONSENT'],
    },
    message: {
      label: { pl: 'Wiadomość', en: 'Message' },
      placeholder: { pl: '', en: '' },
      type: 'message',
      isRequired: false,
    },
  },
  dictonary: {
    send: {
      pl: 'Wyślij',
      en: 'Send',
    },
    fill: {
      pl: 'Wypełnij formularz',
      en: 'Fill up the form',
    },
    fillagreements: {
      pl: 'Zaznacz zgodę',
      en: 'Agree to the terms',
    },
    showMore : {
      pl: 'Pokaż więcej',
      en: 'Show more'
    },
    showLess : {
      pl: 'Pokaż mniej', 
      en: 'Show less'
    },
    success: {
      title: { pl: 'Dziękujemy!', en: 'Thank you!' },
      text: {
        pl: 'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe.',
        en: 'Your message has been sent. We will reply as soon as possible.',
      },
    },
    failure: {
      title: { pl: 'Niepowodzenie!', en: 'Failure!' },
      text: {
        pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
        en: 'It looks like your message has not been sent. Contact us by e-mail or call us',
      },
    },
  },
}