import { React, useState, useEffect } from 'react'
import { useLangContext } from '../context/lang.context'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { content } from '../content/zgoda-rodo'
import { s, sContainer, sContainerPadding } from '../style'
import Headline from '../components/Headline'
import FormConsent from '../components/FormConsent'

export default function ZgodaRodo() {
  const { lang } = useLangContext()
  return (
    <Layout white>
      <Seo
        title={content.seo.title}
        desc={content.seo.desc}
        image={'ogimage.jpg'}
        url={content.seo.url}
        noIndex
      />
      <div css={sContainer}>
        <div css={sContainerPadding}>
          <Headline
            medium
            gradient
            maxWidth
            title={content.title[lang]}
            desc={content.desc[lang]}
          />
          <FormConsent override='CONSENT' data={content} />
        </div>
      </div>
    </Layout>
  )
}